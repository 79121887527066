import React from 'react';

function LawnGarden() {

  return (

  <main class="container my-5">
    <section class="mb-5">
      <div class="container-fluid align-middle head-img mb-5 ">
        <img src="../images/mower.avif" class="img-fluid" alt="Image" />
        <p class="centered">
          Purchase from a large range of Mowers
        </p>
      </div>


      <div class="container-fluid text-center">
        <h3>What We Sell</h3><br/>
        <div class="row">
          <div class="col-sm-3">
            <img src="../images/lawnmower2.jpg" class="img-fluid" alt="Image"/>
            <p>Mower</p>
          </div>
          <div class="col-sm-3">
            <img src="../images/lawnmower3.avif" class="img-fluid" alt="Image"/>
            <p>Mower1</p>
          </div>
          <div class="col-sm-3">
            <img src="https://placehold.it/150x80?text=IMAGE" class="img-fluid" alt="Image"/>
            <p>Mower2</p>
          </div>
          <div class="col-sm-3">
            <div class="well">
              <p>Lawnmowers mow grass very well. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos
                non
                debitis quo enim, possimus est consequuntur. Quidem, rerum inventore culpa velit nam unde sunt? Expedita
                aliquid necessitatibus atque excepturi neque.</p>
            </div>
            <div class="well">
              <p>Some text..</p>
            </div>
          </div>
        </div>
      </div>


      <div class="container-fluid">
        <img src="../images/r4a063080-ag-1366x347.jpg" class="img-fluid" alt="Image"/>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat quod, itaque repellat dolorum ab dolore neque
          aut vitae optio culpa labore pariatur quos, iste fuga ad. Totam facilis quidem voluptatem.</p>

      </div>

      <br />

      {/* <!-- Product links --> */}
      <section class="container-fluid">
        <div class="row">
          <div class="col-sm">
            <h4 class="border border-2 rounded-1 p-1">LAWN TRACTORS</h4>
            <ul>
              <li><a href="https://www.deere.africa/en/mowers/lawn-tractors/100-series/e100-lawn-tractor/">E100</a></li>
              <li><a href="https://www.deere.africa/en/mowers/lawn-tractors/100-series/e110-lawn-tractor/">E110</a></li>
              <li><a href="https://www.deere.africa/en/mowers/lawn-tractors/100-series/e130-lawn-tractor/">E130</a></li>
              <li><a href="https://www.deere.africa/en/mowers/lawn-tractors/100-series/e140-lawn-tractor/">E140</a></li>
              <li><a href="https://www.deere.africa/en/mowers/lawn-tractors/100-series/e180-lawn-tractor/">E180</a></li>
              <li><a
                  href="https://www.deere.africa/en/mowers/lawn-tractors/x300-series/x350-lawn-tractor-collect-mower/">X350R,
                  42-in. rear-discharge deck</a></li>


            </ul>
          </div>
          <div class="col-sm">
            <h4 class="border border-2 rounded-1 p-1">Z - Track</h4>
            <ul>
              <li><a href="https://www.deere.africa/en/tractors/8-series-tractor/">8R Series (230-410 HP)</a></li>
              <li><a href="https://www.deere.africa/en/tractors/4wd-track-tractors/">9R Series (470-570 HP)</a></li>
            </ul>
          </div>
        </div>
      </section>
      </section>
      </main>

);
}

export default LawnGarden;