import { experimentalSetDeliveryMetricsExportedToBigQueryEnabled } from "firebase/messaging/sw";
import react from "react";
import { Helmet } from "react-helmet";
import ProductGrid from "./productGrid";

const tractorProducts = [
  {
    id: 1,
    name: "M Series Front End Loaders",
    image: "/images/jdmfl.avif",
    link: "https://www.deere.africa/en/front-end-loaders-tractors/",
  },
  {
    id: 2,
    name: "H Series Front End Loaders",
    image: "/images/jdhfl.avif",
    link: "https://www.deere.africa/en/front-end-loaders-tractors/",
  },
  {
    id: 3,
    name: "5D Series Tractors",
    image: "/images/jd5d.jpg",
    link: "https://www.deere.africa/en/tractors/5-series-tractors/5d-series/",
  },
  {
    id: 4,
    name: "5E Series Tractors",
    image: "/images/jd5e.avif",
    link: "https://www.deere.africa/en/tractors/5-series-tractors/5e-series/",
  },
  {
    id: 5,
    name: "5EN Series Tractors",
    image: "/images/jd5en.avif",
    link: "https://www.deere.africa/en/tractors/5-series-tractors/5en-series/",
  },
  {
    id: 6,
    name: "6B Series Tractors",
    image: "/images/jd6b.avif",
    link: "https://www.deere.africa/en/tractors/6-series-tractors/6b-series-tractors/",
  },
  {
    id: 7,
    name: "6J Series Tractors",
    image: "/images/jd6j.avif",
    link: "https://www.deere.africa/en/tractors/6-series-tractors/6j-series-tractors/",
  },
  {
    id: 8,
    name: "6M Series Tractors",
    image: "/images/jd6m.avif",
    link: "https://www.deere.africa/en/tractors/6-series-tractors/6m-series-tractors/",
  },
  {
    id: 9,
    name: "6R Series Tractors",
    image: "/images/jd6r.avif",
    link: "https://www.deere.africa/en/tractors/6-series-tractors/6r-series-tractors/",
  },
  {
    id: 10,
    name: "7J Series Tractors",
    image: "/images/jd7j.avif",
    link: "https://www.deere.africa/en/tractors/row-crop-tractors/7-family-row-crop-tractors/7j-series-tractors/",
  },
  {
    id: 11,
    name: "8R Series Tractors",
    image: "/images/jd8r.avif",
    link: "https://www.deere.africa/en/tractors/8-series-tractor/8r-series-tractors//",
  },
  {
    id: 12,
    name: "8RT Series Tractors",
    image: "/images/jd8rt.avif",
    link: "https://www.deere.africa/en/tractors/8-series-tractor/8rt-series-tractors/",
  },
  {
    id: 13,
    name: "8RX Series Tractors",
    image: "/images/jd8rx.avif",
    link: "https://www.deere.africa/en/tractors/8-series-tractor/8rx-series-tractors/",
  },
  {
    id: 14,
    name: "9R Series Tractors",
    image: "/images/jd9r.avif",
    link: "https://www.deere.africa/en/tractors/9-series-tractors/9r-series-tractors/",
  },
  {
    id: 15,
    name: "9RT Series Tractors",
    image: "/images/jd9rt.avif",
    link: "https://www.deere.africa/en/tractors/9-series-tractors/9rt-series-tractors/",
  },
  {
    id: 16,
    name: "9RX Series Tractors",
    image: "/images/jd9rx.avif",
    link: "https://www.deere.africa/en/tractors/9-series-tractors/9rx-series-tractors/",
  },
  {
    id: 17,
    name: "9R Scraper Special Tractors",
    image: "/images/jd9rs.avif",
    link: "https://www.deere.africa/en/tractors/9-series-tractors/9r-scraper-special-tractors/",
  },
];

function AgTractors() {
  return (
    <>
      <Helmet>
        <title>John Deere - Tractors for Modern Farming</title>
        <meta
          name="description"
          content="Explore John Deere's range of tractors. Known for power and precision, these tractors redefine efficiency in agriculture."
        />
        <meta
          name="keywords"
          content="John Deere tractors, agriculture tractors, farm machinery, 6B Series Tractor, modern farming"
        />
        <meta
          property="og:title"
          content="John Deere Tractors - Power and Precision in Agriculture"
        />
        <meta
          property="og:description"
          content="Discover the strength and innovation behind John Deere tractors, trusted for agriculture across diverse landscapes."
        />
        <meta
          property="og:image"
          content="https://swazitrac.com/images/6b-series-tractor.avif"
        />
      </Helmet>
      <main class="container my-5">
        <section class="mb-5 ">
          <div class="head-img container-fluid">
            <img
              src="../images/6b-series-tractor.avif"
              class="img-fluid border-top-deere"
              alt="Image"
            />
            <p>
              John Deere: A Legacy of Power, Precision, and Performance in
              Agriculture.
            </p>
          </div>

          <div class="">
            <p>
              John Deere is a world-renowned leader in agricultural machinery,
              known for its innovative and reliable tractors that redefine
              efficiency in the field. With a broad range of tractor models
              tailored to meet the demands of modern farming, John Deere offers
              cutting-edge solutions for planting, tillage, harvesting, and
              material handling. Built for strength and durability, John Deere
              tractors cater to various applications, from small-scale
              operations to industrial-sized farms, ensuring peak performance
              across diverse landscapes.
            </p>
            <p>
              As a trusted partner in agriculture, John Deere backs its
              equipment with unparalleled customer service, an extensive dealer
              network, and ready access to genuine parts. Farmers can count on
              John Deere to provide not only top-quality tractors but also a
              complete ecosystem of support and expertise. Committed to
              sustainability, John Deere integrates smart technologies to
              enhance productivity and optimize resource use, allowing farmers
              to achieve more with less.
            </p>
            <br></br>
            <p>
              Explore how John Deere tractors can empower your farm operations
              and drive sustainable growth.
            </p>
          </div>

          <div class="container-fluid text-center">
            <ProductGrid products={tractorProducts} />

            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../images/jd5d.jpg"
                  class="img-fluid"
                  alt="John Deere Tractor Image"
                />
                <p>John Deere | Series 5D Tractor</p>
              </div>
              <div class="col-sm-3">
                <img
                  src="../images/jd6r.avif"
                  class="img-fluid"
                  alt="John Deere Tractor Image"
                />
                <p>John Deere | Series 6R Tractor</p>
              </div>
              <div class="col-sm-3">
                <div class="well">
                  <p>
                    John Deere Tractors: The most intelligent tractors in the
                    world. Designed for versatility and productivity, perfect
                    for general farm tasks and specialized applications. With
                    power options ranging from 55 to 125 horsepower, it combines
                    efficiency with user-friendly controls, making it an ideal
                    choice for modern farmers. Whether you're working with tough
                    soil or handling large loads, the 5M Tractor’s
                    high-performance engine and durable build ensure
                    long-lasting performance and ease of operation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <img
              src="../images/jd9r.avif"
              class="img-fluid"
              alt="John Deere Tractors"
            />
          </div>

          <br />

          {/* <!-- Product links --> */}
          <section class="container-fluid">
            <div class="row">
              <div class="col-sm">
                <h4 class="border border-2 rounded-1 p-1">Front End Loaders</h4>
                <ul>
                  <li>
                    <a href="https://www.deere.africa/en/front-end-loaders-tractors/">
                      M Series Front End Loaders
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/front-end-loaders-tractors/">
                      H Series Front End Loaders
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-sm">
                <h4 class="border border-2 rounded-1 p-1">5 Series Tractors</h4>
                <ul>
                  <li>
                    <a href="https://www.deere.africa/en/tractors/5-series-tractors/5d-series/">
                      5D Series
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/tractors/5-series-tractors/5e-series/">
                      5E Series
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/tractors/5-series-tractors/5en-series/">
                      5EN Series
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-sm">
                <h4 class="border border-2 rounded-1 p-1">6 Series Tractors</h4>
                <ul>
                  <li>
                    <a href="https://www.deere.africa/en/tractors/6-series-tractors/6b-series-tractors/">
                      6B Series
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/tractors/6-series-tractors/6j-series-tractors/">
                      6J Series
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/tractors/6-series-tractors/6m-series-tractors/">
                      6M Series
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/tractors/6-series-tractors/6r-series-tractors/">
                      6R Series
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-sm">
                <h4 class="border border-2 rounded-1 p-1">
                  Technology & Insights
                </h4>
                <ul>
                  <li>
                    <a href="https://www.deere.africa/en/forestry/timbermatic-manager/">
                      TimberMatic Maps
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/forestry/timbermatic-manager/timbermanager/">
                      TimberManager
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/forestry/ibc/">
                      Intelligent Boom Control (IBC)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.deere.africa/en/forestry/forestry-articles/">
                      Articles and Stories
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </section>
      </main>
    </>
  );
}

export default AgTractors; // export
